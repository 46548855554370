<template>
  <div
    class="player-setup-header-action"
  >
    <div>
      <Tooltip
        v-if="isRemoveActive"
        text="Remove"
        left
      >
        <Icon
          name="trash"
          @click="removePlayer"
        />
      </Tooltip>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';

export default {
  components: {
    Icon,
    Tooltip,
  },
  props: {
    playerData: {
      type: Object,
      required: true,
    },
    teamLabel: {
      type: String,
      default: '',
    },
    activeTab: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const store = useStore();
    const isRemoveActive = computed(() => !props.playerData?.playerLocked);
    const removePlayer = () => store.dispatch('removePlayer', {
      playerData: props.playerData,
      teamLabel: props.teamLabel,
      activeTab: props.activeTab.key,
    });
    return {
      isRemoveActive,
      removePlayer,
    };
  },
};
</script>

<style lang="scss">
.player-setup-header-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  .tooltip-element {
    width: 18px;
    height: 18px;
  }
}
</style>
