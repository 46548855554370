<template>
  <div class="baseball-player-params-by-team-root">
    <ParamsTabs
      class="batters-pitchers-tabs"
      :tabs="battersPitchersTabs"
      :active-tab="activeTab"
      @onTabChange="onTabChange"
    />
    <div
      v-if="activeTabTeamData.length"
      class="baseball-player-params-by-team-list-root"
    >
      <BaseballPlayerParamsSingle
        v-for="player in activeTabTeamData"
        :key="player.playerId"
        :player-data="player"
        :active-tab="activeTab"
        :team-label="teamLabel"
        :current-catcher-index="currentCatcherIndex"
        :is-calculated-mode="isCalculatedMode"
        @onParamUpdate="updatePlayerParam"
      />
    </div>
    <div
      v-else
      class="baseball-player-params-by-team-list-root-no-data"
    >
      There is no {{ toLower(activeTab.label) }} data.
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import {
  filter, cloneDeep, find, set, toLower, findIndex,
  orderBy, get,
} from 'lodash';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import ParamsTabs from '@/components/player-setup/ParamsTabs';
import BaseballPlayerParamsSingle from '@/components/player-setup/baseball/BaseballPlayerParamsSingle';
import { updateTeamPlayersPositionValue } from '@/components/player-setup/params-helper';

export default {
  emits: ['onPitcherHitterTabChange'],
  props: {
    teamData: {
      type: Object,
      default: () => ({}),
    },
    teamDataFiltered: {
      type: Object,
      default: null,
    },
    teamLabel: {
      type: String,
      default: '',
    },
    currentCatcherIndexProp: {
      type: Number,
      default: null,
    },
    isCalculatedMode: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ParamsTabs,
    BaseballPlayerParamsSingle,
  },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const battersPitchersTabs = computed(() => {
      const tabs = [
        {
          key: 'pitchers',
          label: 'Pitchers',
        },
        {
          key: 'hitters',
          label: 'Hitters',
        },
      ];
      return tabs;
    });
    const activeTab = ref(battersPitchersTabs.value[0]);
    const activeTabTeamData = computed(() => {
      let data;
      if (activeTab.value.key === 'pitchers') {
        data = filter(props.teamDataFiltered || props.teamData, { isPitcher: true });
      } else {
        data = filter(props.teamDataFiltered || props.teamData, { isHitter: true });
      }
      return orderBy(data, 'playerPositionIndex');
    });
    const onTabChange = (tab) => {
      activeTab.value = tab;
      emit('onPitcherHitterTabChange', tab.key);
    };
    const playersData = computed(() => {
      if (route.name === 'trading-ui') {
        return store.getters.tradingPlayerProps;
      }
      if (route.name === 'trading-ui-multiview') {
        return store.getters.multiviewPlayerParams;
      }
      return store.getters.playerSetupTableData;
    });
    const currentCatcherIndex = computed(() => props.currentCatcherIndexProp || find(playersData.value[props.teamLabel], ({ hitterParams }) => hitterParams?.isCatcher)?.playerIndex);
    const updatePlayerParam = ({
      playerId, paramKey, value, updateKey, pitcherParamsUpdated,
    }) => {
      const playersDataUpdated = cloneDeep(playersData.value);
      let playerDataByTeamUpdated = cloneDeep(props.teamData);
      const updatedRow = cloneDeep(find(playerDataByTeamUpdated, { playerId }));
      const updatedPlayerIndex = findIndex(playerDataByTeamUpdated, (player) => player.playerId === updatedRow.playerId);

      if (paramKey === 'playerPositionIndex') {
        playerDataByTeamUpdated = updateTeamPlayersPositionValue(playerDataByTeamUpdated, playerId, value, playerDataByTeamUpdated[updatedPlayerIndex]?.playerPositionIndex);
      }
      if (updateKey) {
        if (pitcherParamsUpdated) {
          const updatedPitcherParams = cloneDeep(updatedRow.pitcherParams);
          const catcherFound = !!get(updatedPitcherParams?.catcherPitcherProjections, currentCatcherIndex.value);
          if (catcherFound) {
            set(updatedPitcherParams.catcherPitcherProjections[currentCatcherIndex.value], updateKey, value);
          } else {
            set(updatedPitcherParams.noCatcherDefinedProjection, updateKey, value);
          }
          set(updatedRow, 'pitcherParams', updatedPitcherParams);
        } else {
          const updatedHitterParams = cloneDeep(updatedRow.hitterParams);
          set(updatedHitterParams, updateKey, value);
          set(updatedRow, 'hitterParams', updatedHitterParams);
        }
      } else {
        set(updatedRow, paramKey, value);
      }

      playerDataByTeamUpdated[updatedPlayerIndex] = updatedRow;
      if (route.name === 'trading-ui') {
        playersDataUpdated[props.teamLabel].playerProps = playerDataByTeamUpdated;
        store.dispatch('updateTradingPlayerProps', playersDataUpdated);
        return;
      } if (route.name === 'trading-ui-multiview') {
        playersDataUpdated[props.teamLabel].playerProps = playerDataByTeamUpdated;
        store.dispatch('updateMultiviewPlayerProps', playersDataUpdated);
        return;
      }
      playersDataUpdated[props.teamLabel] = playerDataByTeamUpdated;
      store.dispatch('setPlayerSetupTableData', playersDataUpdated);
    };

    return {
      battersPitchersTabs,
      activeTab,
      activeTabTeamData,
      onTabChange,
      updatePlayerParam,
      toLower,
      currentCatcherIndex,
    };
  },
};
</script>

<style lang="scss">
.baseball-player-params-by-team-root {
  height: 100%;
  .batters-pitchers-tabs {
    padding-left: 0;
    padding-right: 0;
  }

  .baseball-player-params-by-team-list-root {
    height: calc(100% - 64px);
    overflow-y: auto;
  }
  .baseball-player-params-by-team-list-root-no-data {
    height: calc(100% - 64px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
  }

  .modal {
    .modal__header {
      padding: 16px 16px 0 16px;
      .modal__title {
        padding: 0;
      }
    }
    .modal__content {
      padding: 16px;
    }
  }
}
</style>
