<template>
  <div class="basketball-player-stats">
    <div class="basketball-player-stats__item">
      <div class="basketball-player-stats__item-label">
        Minutes played:
      </div>
      <div class="basketball-player-stats__item-value">
        <strong>
          {{ getTimeInMinutesValue('minutesPlayed') }} min
        </strong>
      </div>
    </div>
    <div class="basketball-player-stats__item">
      <div class="basketball-player-stats__item-label">
        Points:
      </div>
      <div class="basketball-player-stats__item-value">
        <strong>
          {{ getValue('totalPoints') }}&nbsp;
        </strong>
        <span>
          {{ getValueByPeriod('points') }}
        </span>
      </div>
    </div>
    <div class="basketball-player-stats__item">
      <div class="basketball-player-stats__item-label">
        Rebounds:
      </div>
      <div class="basketball-player-stats__item-value">
        <strong>
          {{ getValue('totalRebounds') }}&nbsp;
        </strong>
        <span>
          {{ getValueByPeriod('rebounds') }}
        </span>
      </div>
    </div>
    <div class="basketball-player-stats__item">
      <div class="basketball-player-stats__item-label">
        Fouls:
      </div>
      <div class="basketball-player-stats__item-value">
        <strong>
          {{ getValue('totalFouls') }}&nbsp;
        </strong>
        <span>
          {{ getValueByPeriod('fouls') }}
        </span>
      </div>
    </div>
    <div class="basketball-player-stats__item">
      <div class="basketball-player-stats__item-label">
        Last time subbed:
      </div>
      <div class="basketball-player-stats__item-value">
        <strong>
          {{ formatPeriodLabel(getValue('periodOfLastSubIn')) }}&nbsp;{{ formatPeriodTime(getValue('timeInPeriodOfLastSubIn')) }}
        </strong>
      </div>
    </div>
    <div class="basketball-player-stats__item">
      <div class="basketball-player-stats__item-label">
        Three pointers:
      </div>
      <div class="basketball-player-stats__item-value">
        <strong>
          {{ getValue('totalThreePointers') }}&nbsp;
        </strong>
        <span>
          {{ getValueByPeriod('threePointers') }}
        </span>
      </div>
    </div>
    <div class="basketball-player-stats__item">
      <div class="basketball-player-stats__item-label">
        Assists:
      </div>
      <div class="basketball-player-stats__item-value">
        <strong>
          {{ getValue('totalAssists') }}&nbsp;
        </strong>
        <span>
          {{ getValueByPeriod('assists') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  reduce, join, isFinite, isNil,
} from 'lodash';
import { formatMinutesAndSeconds } from '@/utils/number';
import basketballHelpers from '@/services/helpers/basketball';

export default {
  props: {
    stats: {
      type: Object,
      default: null,
    },
    competitionType: {
      type: String,
      default: 'NBA',
    },
  },
  setup(props) {
    const getValue = (key) => props.stats?.[key] ?? '-';
    const getTimeInMinutesValue = (key) => {
      const value = props.stats?.[key];
      if (isNil(value)) return '-';
      return formatMinutesAndSeconds(value * 60);
    };

    const getValueByPeriod = (key) => {
      const value = reduce(
        props.stats?.periodStats ?? {},
        (valueArray, values) => [...valueArray, (values[key] ?? '-')],
        [],
      );
      if (!value.length) return '';
      return `(${join(value, ', ')})`;
    };
    const formatPeriodLabel = (value) => basketballHelpers.formatPeriodLabel({ competitionType: props.competitionType, period: value }) || 'N/A';
    const formatPeriodTime = (value) => (isFinite(value) ? formatMinutesAndSeconds(value) : '-');

    return {
      getValue,
      getTimeInMinutesValue,
      getValueByPeriod,
      formatPeriodLabel,
      formatPeriodTime,
    };
  },
};
</script>

<style lang="scss">
.basketball-player-stats {
  display: grid;
  grid-template-columns: repeat(4, auto);
  row-gap: 8px;
  column-gap: 8px;
  padding: 16px;

  .basketball-player-stats__item {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .basketball-player-stats__item-label {
    color: #6D6D6D;
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .basketball-player-stats__item-value {
    color: #6D6D6D;
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
  }
}
</style>
