<template>
  <div class="baseball-player-stats">
    <div
      v-if="showPitcherStats"
      class="baseball-player-stats__group"
    >
      <div class="baseball-player-stats__group-header">
        As pitcher
      </div>
      <div class="baseball-player-stats__group-body">
        <div
          v-for="field in pitcherStatFields"
          :key="field.value"
          class="baseball-player-stats__item"
        >
          <div class="baseball-player-stats__item-label">
            {{ field.label }}:
          </div>
          <div class="baseball-player-stats__item-value">
            {{ getPitcherValue(field.value) }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showHitterStats"
      class="baseball-player-stats__group"
    >
      <div class="baseball-player-stats__group-header">
        As batter
      </div>
      <div class="baseball-player-stats__group-body">
        <div
          v-for="field in hitterStatFields"
          :key="field.value"
          class="baseball-player-stats__item"
        >
          <div class="baseball-player-stats__item-label">
            {{ field.label }}:
          </div>
          <div class="baseball-player-stats__item-value">
            {{ getBatterValue(field.value) }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showNoStats"
      class="baseball-player-stats__group"
    >
      <div class="baseball-player-stats__group-body">
        <div class="baseball-player-stats__group-label">
          No statistics for this player.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty, isNil } from 'lodash';
import { computed } from 'vue';

const pitcherStatFields = [
  {
    label: 'Hits',
    value: 'hits',
  },
  {
    label: 'Runs',
    value: 'runs',
  },
  {
    label: 'Home Runs',
    value: 'homeRuns',
  },
  {
    label: 'Walks',
    value: 'walks',
  },
  {
    label: 'Strikeouts',
    value: 'strikeOuts',
  },
  {
    label: 'Batters Faced',
    value: 'battersFaced',
  },
  {
    label: 'Stolen Bases',
    value: 'stolenBases',
  },
];

const hitterStatFields = [
  {
    label: 'At Bats',
    value: 'atBats',
  },
  {
    label: 'Runs',
    value: 'runs',
  },
  {
    label: 'Hits',
    value: 'hits',
  },
  {
    label: 'Singles',
    value: 'singles',
  },
  {
    label: 'Doubles',
    value: 'doubles',
  },
  {
    label: 'Triples',
    value: 'triples',
  },
  {
    label: 'Home Runs',
    value: 'homeRuns',
  },
  {
    label: 'Runs Batted In',
    value: 'rbis',
  },
  {
    label: 'Walks',
    value: 'walks',
  },
  {
    label: 'Strikeouts',
    value: 'strikeOuts',
  },
  {
    label: 'Hit-by-Pitch',
    value: 'hitByPitch',
  },
  {
    label: 'Total Bases',
    value: 'bases',
  },
];

export default {
  props: {
    stats: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const showPitcherStats = computed(() => !isNil(props.stats.pitcher));
    const showHitterStats = computed(() => !isNil(props.stats.batter));
    const showNoStats = computed(() => isNil(props.stats) || isEmpty(props.stats));

    const getPitcherValue = (key) => props.stats?.pitcher?.[key] ?? '-';
    const getBatterValue = (key) => props.stats?.batter?.[key] ?? '-';

    return {
      pitcherStatFields,
      hitterStatFields,
      showPitcherStats,
      showHitterStats,
      showNoStats,
      getPitcherValue,
      getBatterValue,
    };
  },
};
</script>

<style lang="scss">
.baseball-player-stats {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  &__group {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-header {
      color: #191414;
      font-family: Rubik;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &-body {
      display: grid;
      grid-template-columns: repeat(4, auto);
      row-gap: 8px;
      column-gap: 8px;
    }

    &-label {
      color: #191414;
      font-family: Rubik;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 4px;

    &-label {
      color: #6D6D6D;
      font-family: Rubik;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &-value {
      color: #6D6D6D;
      font-family: Rubik;
      font-size: 12px;
      font-style: normal;
      line-height: normal;
      font-weight: 600;
    }
  }
}
</style>
