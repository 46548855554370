<template>
  <ModalWithDetails
    class="edit-market-list-modal"
    title="Edit market list"
    :subtitle="`Check which markets are going to be offered for ${eventName}`"
    submit-button-label="Save changes"
    :is-submit-button-disabled="isMarketTemplateListTheSame"
    :is-submit-button-loading="isProcessing"
    :show-details-label="`Show selected markets (${selectedMarketList.length})`"
    @dismiss="dismiss"
    @submit="submit"
  >
    <template
      v-if="isLoading"
      #default
    >
      <div class="edit-market-list-modal__loader">
        <Spinner />
      </div>
    </template>
    <template
      v-else
      #default
    >
      <div class="edit-market-list-modal__content">
        <TabGroup>
          <Tab
            v-for="group in marketGroups"
            :key="group.id"
            :is-active="selectedMarketGroupId === group.id"
            @click="setSelectedMarketGroupId(group.id)"
          >
            {{ group.name }}
          </Tab>
        </TabGroup>
        <SelectableEditList
          :items="marketListBySelectedMarketGroup"
          :selected-items="selectedMarketList"
          search-placeholder="Search market"
          empty-state-placeholder="No markets"
          @update:selected-items="setSelectedMarketList"
        />
      </div>
    </template>
    <template #details>
      <div class="edit-market-list-modal__selected-list">
        <div class="edit-market-list-modal__selected-title">
          Selected markets
        </div>
        <div
          v-if="!selectedMarketList.length"
          class="edit-market-list-modal__selected-item"
        >
          No selected markets
        </div>
        <div
          v-for="(selectedMarket, index) in sortedSelectedMarketList"
          :key="selectedMarket.id"
          class="edit-market-list-modal__selected-item"
        >
          <span class="edit-market-list-modal__selected-item-number">
            {{ index + 1 }}
          </span>
          <span class="edit-market-list-modal__selected-item-name">
            {{ selectedMarket.name }}
          </span>
        </div>
      </div>
    </template>
  </ModalWithDetails>
</template>

<script>
import { map, isEqual } from 'lodash';
import { ref, computed, onMounted } from 'vue';
import { findEditMarketListData } from '@/services/api';
import {
  parseMarketList,
  parseMarketGroups,
  parseSelectedMarketList,
  filterMarketListByMarketGroupId,
  sortMarketList,
} from '@/services/helpers/market-templates-list';
import ModalWithDetails from '@/components/common/ModalWithDetails';
import Spinner from '@/components/common/Spinner';
import TabGroup from '@/components/common/TabGroup';
import Tab from '@/components/common/Tab';
import SelectableEditList from '@/components/common/SelectableEditList';

export default {
  components: {
    ModalWithDetails,
    Spinner,
    TabGroup,
    Tab,
    SelectableEditList,
  },
  props: {
    sportId: {
      type: String,
      required: true,
    },
    marketTemplateIds: {
      type: Array,
      required: true,
    },
    eventName: {
      type: String,
      default: 'N/A vs N/A',
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:marketTemplateIds': {
      type: Array,
    },
    close: {
    },
  },
  setup(props, { emit }) {
    const isLoading = ref(false);
    const marketGroups = ref([]);
    const selectedMarketGroupId = ref('');
    const marketList = ref([]);
    const selectedMarketList = ref([]);
    const sortedSelectedMarketList = computed(() => sortMarketList(selectedMarketList.value, marketGroups.value));
    const marketListBySelectedMarketGroup = computed(() => sortMarketList(
      filterMarketListByMarketGroupId(marketList.value, selectedMarketGroupId.value),
      marketGroups.value,
    ));
    const isMarketTemplateListTheSame = computed(() => isEqual(props.marketTemplateIds, map(selectedMarketList.value, 'id')));

    const setSelectedMarketGroupId = (newSelectedMarketGroupId) => {
      selectedMarketGroupId.value = newSelectedMarketGroupId;
    };
    const setSelectedMarketList = (newSelectedMarketList) => {
      selectedMarketList.value = newSelectedMarketList;
    };
    const load = async () => {
      isLoading.value = true;
      try {
        const response = await findEditMarketListData(props.sportId);
        const marketGroupsPayload = response.data?.marketGroups?.nodes || [];
        const marketTemplatesPayload = response.data?.marketTemplates?.nodes || [];
        marketList.value = parseMarketList(marketGroupsPayload, marketTemplatesPayload);
        marketGroups.value = parseMarketGroups(marketGroupsPayload, marketList.value);
        selectedMarketList.value = parseSelectedMarketList(props.marketTemplateIds, marketList.value);
      } catch (error) {
        console.error(error);
        marketList.value = [];
        marketGroups.value = [];
      } finally {
        isLoading.value = false;
      }
    };
    const dismiss = () => {
      emit('close');
    };
    const submit = () => {
      emit('update:marketTemplateIds', map(selectedMarketList.value, 'id'));
    };

    onMounted(load);

    return {
      isLoading,
      marketGroups,
      selectedMarketGroupId,
      marketList,
      selectedMarketList,
      sortedSelectedMarketList,
      marketListBySelectedMarketGroup,
      isMarketTemplateListTheSame,
      setSelectedMarketGroupId,
      setSelectedMarketList,
      dismiss,
      submit,
    };
  },
};
</script>

<style lang="scss">
.edit-market-list-modal {
  .modal-with-details__main-frame {
    height: 763px;
    min-height: 763px;
    max-height: 763px;
  }

  .modal-with-details__aside-frame {
    max-height: 763px;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .tab-group {
      margin: 0 8px;
    }
  }

  &__selected-list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__selected-title {
    position: sticky;
    top: 0;
    background-color: #FAFAFA;
    font-family: 'Rubik', sans-serif;
    font-size: 10px;
    line-height: 11.85px;
    font-weight: 600;
    padding: 8px;
    color: #A9A9A9;
    text-transform: uppercase;
  }

  &__selected-item {
    display: flex;
    gap: 4px;
    padding: 4px 8px;

    &-number {
      width: 25px;
      min-width: 25px;
      max-width: 25px;
    }
  }
}
</style>
