<template>
  <div class="football-player-stats">
    <div class="football-player-stats__item">
      <div class="football-player-stats__item-label">
        Pass completions:
      </div>
      <div class="football-player-stats__item-value">
        {{ getValue('passAttempts') }}/{{ getValue('passCompletions') }}
      </div>
    </div>
    <div class="football-player-stats__item">
      <div class="football-player-stats__item-label">
        Passing yards:
      </div>
      <div class="football-player-stats__item-value">
        {{ getValue('passingYards') }}
      </div>
    </div>
    <div class="football-player-stats__item">
      <div class="football-player-stats__item-label">
        Passing TDs:
      </div>
      <div class="football-player-stats__item-value">
        {{ getValue('passingTouchdowns') }}
      </div>
    </div>
    <div class="football-player-stats__item">
      <div class="football-player-stats__item-label">
        Receptions:
      </div>
      <div class="football-player-stats__item-value">
        {{ getValue('receptions') }}
      </div>
    </div>
    <div class="football-player-stats__item">
      <div class="football-player-stats__item-label">
        Receiving yards:
      </div>
      <div class="football-player-stats__item-value">
        {{ getValue('receivingYards') }}
      </div>
    </div>
    <div class="football-player-stats__item">
      <div class="football-player-stats__item-label">
        Rushing yards:
      </div>
      <div class="football-player-stats__item-value">
        {{ getValue('rushingYards') }}
      </div>
    </div>
    <div class="football-player-stats__item">
      <div class="football-player-stats__item-label">
        Rushing attempts:
      </div>
      <div class="football-player-stats__item-value">
        {{ getValue('rushingAttempts') }}
      </div>
    </div>
    <div class="football-player-stats__item">
      <div class="football-player-stats__item-label">
        Touchdowns:
      </div>
      <div class="football-player-stats__item-value">
        {{ getValue('touchdowns') }}
      </div>
    </div>
    <div class="football-player-stats__item">
      <div class="football-player-stats__item-label">
        Touchdowns yards:
      </div>
      <div class="football-player-stats__item-value">
        {{ getValue('touchdownYards') }}
      </div>
    </div>
    <div class="football-player-stats__item">
      <div class="football-player-stats__item-label">
        Interceptions:
      </div>
      <div class="football-player-stats__item-value">
        {{ getValue('interceptions') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stats: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const getValue = (key) => props.stats?.[key] ?? '-';

    return {
      getValue,
    };
  },
};
</script>

<style lang="scss">
.football-player-stats {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 8px;
  column-gap: 8px;
  padding: 16px;

  .football-player-stats__item {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .football-player-stats__item-label {
    color: #6D6D6D;
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .football-player-stats__item-value {
    color: #6D6D6D;
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
</style>
