<template>
  <PlayerParamsSingle
    :player-data="playerData"
    :active-tab="activeTab"
    :team-label="teamLabel"
    :is-calculated-mode="isCalculatedMode"
    :handedness="handedness"
    :active-params="activeTabParams"
    :sport-id="BASEBALL_ID"
    @onParamUpdate="onParamUpdate"
    @onPlayerParamUpdate="updateParam"
  />
</template>

<script>
import { computed } from 'vue';
import {
  map, has, get,
} from 'lodash';
import { createBaseballPlayerPitcherParams, createBaseballPlayerHitterParams } from '@/components/player-setup/params-helper';
import sportIds from '@/services/helpers/sports';
import PlayerParamsSingle from '@/components/player-setup/common/PlayerParamsSingle';

const {
  BASEBALL_ID,
} = sportIds;

export default {
  emits: ['onParamUpdate'],
  props: {
    playerData: {
      type: Object,
      default: () => ({}),
    },
    activeTab: {
      type: Object,
      default: () => ({}),
    },
    teamLabel: {
      type: String,
      default: '',
    },
    currentCatcherIndex: {
      type: Number || null,
      default: null,
    },
    isCalculatedMode: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PlayerParamsSingle,
  },
  setup(props, { emit }) {
    const mergeParamsWithConfig = (params, config) => map(config, (param) => {
      const value = has(params, param.key) ? params[param.key] : 0;
      return {
        ...param,
        isError: value < param?.minValue || value > param?.maxValue,
        errorMessage: `${param?.shortName} must be a value between ${param?.minValue} and ${param?.maxValue}`,
        value,
      };
    });
    // Pitcher params
    const picherConfig = createBaseballPlayerPitcherParams();
    const pitcherParams = computed(() => {
      const hasPitcherParams = has(props.playerData, 'pitcherParams');
      if (!hasPitcherParams) return {};
      const pitcherParamsUpdated = get(props.playerData, 'pitcherParams');
      let againstLeftHandedEff;
      let againstRightHandedEff;
      let leftHandedHomeRunProb;
      let rightHandedHomeRunProb;
      let leftHandedStrikeOutProb;
      let rightHandedStrikeOutProb;
      let leftHandedSingleProb;
      let leftHandedDoubleOrTripleProb;
      let rightHandedSingleProb;
      let rightHandedDoubleOrTripleProb;
      const catcherPitcherProjectionsByCatcherIndex = get(pitcherParamsUpdated?.catcherPitcherProjections, props.currentCatcherIndex);
      if (catcherPitcherProjectionsByCatcherIndex) {
        againstLeftHandedEff = catcherPitcherProjectionsByCatcherIndex?.againstLeftHandedEff;
        againstRightHandedEff = catcherPitcherProjectionsByCatcherIndex?.againstRightHandedEff;
        leftHandedHomeRunProb = catcherPitcherProjectionsByCatcherIndex?.leftHandedProjection?.homeRunProb;
        rightHandedHomeRunProb = catcherPitcherProjectionsByCatcherIndex?.rightHandedProjection?.homeRunProb;
        leftHandedStrikeOutProb = catcherPitcherProjectionsByCatcherIndex?.leftHandedProjection?.strikeOutProb;
        rightHandedStrikeOutProb = catcherPitcherProjectionsByCatcherIndex?.rightHandedProjection?.strikeOutProb;
        leftHandedSingleProb = catcherPitcherProjectionsByCatcherIndex?.leftHandedProjection?.singleProb;
        leftHandedDoubleOrTripleProb = catcherPitcherProjectionsByCatcherIndex?.leftHandedProjection?.doubleOrTripleProb;
        rightHandedSingleProb = catcherPitcherProjectionsByCatcherIndex?.rightHandedProjection?.singleProb;
        rightHandedDoubleOrTripleProb = catcherPitcherProjectionsByCatcherIndex?.rightHandedProjection?.doubleOrTripleProb;
      } else {
        const noCatcherDefinedProjection = pitcherParamsUpdated?.noCatcherDefinedProjection;
        againstLeftHandedEff = get(noCatcherDefinedProjection, 'againstLeftHandedEff');
        againstRightHandedEff = get(noCatcherDefinedProjection, 'againstRightHandedEff');
        leftHandedHomeRunProb = get(noCatcherDefinedProjection, 'leftHandedProjection.homeRunProb');
        rightHandedHomeRunProb = get(noCatcherDefinedProjection, 'rightHandedProjection.homeRunProb');
        leftHandedStrikeOutProb = get(noCatcherDefinedProjection, 'leftHandedProjection.strikeOutProb');
        rightHandedStrikeOutProb = get(noCatcherDefinedProjection, 'rightHandedProjection.strikeOutProb');
        leftHandedSingleProb = get(noCatcherDefinedProjection, 'leftHandedProjection.singleProb');
        leftHandedDoubleOrTripleProb = get(noCatcherDefinedProjection, 'leftHandedProjection.doubleOrTripleProb');
        rightHandedSingleProb = get(noCatcherDefinedProjection, 'rightHandedProjection.singleProb');
        rightHandedDoubleOrTripleProb = get(noCatcherDefinedProjection, 'rightHandedProjection.doubleOrTripleProb');
      }
      return {
        ...pitcherParamsUpdated,
        againstLeftHandedEff: againstLeftHandedEff ? parseFloat((againstLeftHandedEff * 100).toFixed(2)) : 0,
        againstRightHandedEff: againstRightHandedEff ? parseFloat((againstRightHandedEff * 100).toFixed(2)) : 0,
        leftHandedHomeRunProb: leftHandedHomeRunProb ? parseFloat(leftHandedHomeRunProb.toFixed(3)) : 0,
        rightHandedHomeRunProb: rightHandedHomeRunProb ? parseFloat(rightHandedHomeRunProb.toFixed(3)) : 0,
        leftHandedStrikeOutProb: leftHandedStrikeOutProb ? parseFloat(leftHandedStrikeOutProb.toFixed(3)) : 0,
        rightHandedStrikeOutProb: rightHandedStrikeOutProb ? parseFloat(rightHandedStrikeOutProb.toFixed(3)) : 0,
        leftHandedSingleProb: leftHandedSingleProb ? parseFloat(leftHandedSingleProb.toFixed(3)) : 0,
        leftHandedDoubleOrTripleProb: leftHandedDoubleOrTripleProb ? parseFloat(leftHandedDoubleOrTripleProb.toFixed(3)) : 0,
        rightHandedSingleProb: rightHandedSingleProb ? parseFloat(rightHandedSingleProb.toFixed(3)) : 0,
        rightHandedDoubleOrTripleProb: rightHandedDoubleOrTripleProb ? parseFloat(rightHandedDoubleOrTripleProb.toFixed(3)) : 0,
      };
    });
    const pitcherParamsConfig = computed(() => mergeParamsWithConfig(pitcherParams.value, picherConfig));
    // Hitter params
    const hitterConfig = createBaseballPlayerHitterParams();
    const hitterParams = computed(() => {
      const hasHitterParams = has(props.playerData, 'hitterParams');
      if (!hasHitterParams) return {};
      const hitterParamsUpdated = get(props.playerData, 'hitterParams');
      const againstLeftHandedEff = get(hitterParamsUpdated, 'againstLeftHandedEff');
      const againstRightHandedEff = get(hitterParamsUpdated, 'againstRightHandedEff');
      const leftHandedHomeRunProb = get(hitterParamsUpdated, 'leftHandedProjection.homeRunProb');
      const rightHandedHomeRunProb = get(hitterParamsUpdated, 'rightHandedProjection.homeRunProb');
      const leftHandedStrikeOutProb = get(hitterParamsUpdated, 'leftHandedProjection.strikeOutProb');
      const rightHandedStrikeOutProb = get(hitterParamsUpdated, 'rightHandedProjection.strikeOutProb');
      const leftHandedSingleProb = get(hitterParamsUpdated, 'leftHandedProjection.singleProb');
      const leftHandedDoubleOrTripleProb = get(hitterParamsUpdated, 'leftHandedProjection.doubleOrTripleProb');
      const rightHandedSingleProb = get(hitterParamsUpdated, 'rightHandedProjection.singleProb');
      const rightHandedDoubleOrTripleProb = get(hitterParamsUpdated, 'rightHandedProjection.doubleOrTripleProb');

      return {
        ...hitterParamsUpdated,
        againstLeftHandedEff: againstLeftHandedEff ? parseFloat((againstLeftHandedEff * 100).toFixed(2)) : 0,
        againstRightHandedEff: againstRightHandedEff ? parseFloat((againstRightHandedEff * 100).toFixed(2)) : 0,
        leftHandedHomeRunProb: leftHandedHomeRunProb ? parseFloat(leftHandedHomeRunProb.toFixed(3)) : 0,
        rightHandedHomeRunProb: rightHandedHomeRunProb ? parseFloat(rightHandedHomeRunProb.toFixed(3)) : 0,
        leftHandedStrikeOutProb: leftHandedStrikeOutProb ? parseFloat(leftHandedStrikeOutProb.toFixed(3)) : 0,
        rightHandedStrikeOutProb: rightHandedStrikeOutProb ? parseFloat(rightHandedStrikeOutProb.toFixed(3)) : 0,
        leftHandedSingleProb: leftHandedSingleProb ? parseFloat(leftHandedSingleProb.toFixed(3)) : 0,
        leftHandedDoubleOrTripleProb: leftHandedDoubleOrTripleProb ? parseFloat(leftHandedDoubleOrTripleProb.toFixed(3)) : 0,
        rightHandedSingleProb: rightHandedSingleProb ? parseFloat(rightHandedSingleProb.toFixed(3)) : 0,
        rightHandedDoubleOrTripleProb: rightHandedDoubleOrTripleProb ? parseFloat(rightHandedDoubleOrTripleProb.toFixed(3)) : 0,
      };
    });
    const hitterParamsConfig = computed(() => mergeParamsWithConfig(hitterParams.value, hitterConfig));

    const handedness = computed(() => {
      const hand = hitterParams.value?.handedness || pitcherParams.value?.handedness || 'RIGHT';
      if (hand === 'RIGHT') return 'Right hand';
      if (hand === 'LEFT') return 'Left hand';
      if (hand === 'SWITCH') return 'Both hands';
      return 'Right hand';
    });

    const activeTabParams = computed(() => {
      if (props.activeTab.key === 'pitchers') {
        return pitcherParamsConfig.value;
      }
      if (props.activeTab.key === 'hitters') {
        return hitterParamsConfig.value;
      }
      return [];
    });

    const updateParam = ({ newVal, param }) => {
      emit('onParamUpdate', {
        playerId: props.playerData.playerId,
        paramKey: `${props.activeTab.key === 'pitchers' ? 'pitcherParams' : 'hitterParams'}.${param.key}`,
        value: newVal,
        updateKey: param.updateKey,
        pitcherParamsUpdated: props.activeTab.key === 'pitchers',
      });
    };

    const onParamUpdate = (paramUpdated) => {
      emit('onParamUpdate', paramUpdated);
    };

    return {
      activeTabParams,
      handedness,
      BASEBALL_ID,
      onParamUpdate,
      updateParam,
    };
  },
};
</script>

<style lang="scss">
</style>
