<template>
  <div class="params-setup-markets-root">
    <div class="markets-header">
      <div class="title">
        <span>Markets</span>
      </div>
      <div class="market-header-inputs">
        <div
          v-if="isCustomizingMarketTemplatesVisible"
          :class="[
            'market-template-control',
            { 'market-template-control--is-processing': isProcessingCustomizedMarketTemplates },
          ]"
        >
          <div
            class="market-template-control__toggle"
            @click="setUsingDefaultMarketTemplates(!isUsingDefaultMarketTemplates)"
          >
            <Spinner
              v-if="isProcessingCustomizedMarketTemplates"
              small
            />
            <Toggle
              v-else
              :model-value="isUsingDefaultMarketTemplates"
              @update:model-value="setUsingDefaultMarketTemplates"
              :disabled="!isCustomizingMarketTemplatesSupported"
            />
            Use default market templates
          </div>
          <Button
            variant="primary"
            :disabled="!isCustomizingMarketTemplatesSupported || isUsingDefaultMarketTemplates"
            @click="openMarketList"
          >
            Edit market list
          </Button>
          <EditMarketListModal
            v-if="isMarketListOpen"
            :sport-id="eventData.sportId"
            :market-template-ids="customMarketTemplateIds"
            :event-name="eventData.eventName"
            :is-processing="isProcessingCustomizedMarketTemplates"
            @update:market-template-ids="setCustomMarketTemplateIds"
            @close="closeMarketList"
          />
        </div>
        <TextInput
          :model-value="marketSearchValue"
          @update:model-value="onMarketSearchValueChage"
          :placeholder="'Search market'"
          :icon="'search'"
          small
        />
      </div>
    </div>
    <div class="markets-data">
      <div
        class="market-item"
        v-for="market in marketsFiltered"
        :key="market.label"
      >
        <div class="market-info">
          <span>{{ market.label }}</span>
        </div>
        <div
          v-if="showMarketPrices(market)"
          class="market-prices"
        >
          <!-- MAIN LINE -->
          <div class="market-price">
            <NumberInput
              class="number-input"
              v-if="getMainLine(market)"
              :model-value="getMainLine(market)"
              :readonly="isPricesReadonly"
              :number-of-decimals="1"
              :is-error="market.isMainLineInvalid"
              :accept-negative="market.acceptNegative"
              :max="500"
              :min="-500"
              @update:modelValue="updateMainLine($event, market)"
            />
          </div>
          <!-- OVER SELECTION -->
          <div
            v-if="showMarketOverSelection(market)"
            class="market-price"
          >
            <NumberInput
              class="number-input"
              :model-value="getMarketOverSelection(market)"
              :readonly="isPricesReadonly"
              :number-of-decimals="3"
              :is-error="isOverSelectionError(market)"
              @update:modelValue="updateMarketOverUnderSelection($event, market)"
            />
          </div>
          <!-- HOME SELECTION -->
          <div
            v-if="showMarketHomeSelection(market)"
            class="market-price"
          >
            <NumberInput
              class="number-input"
              :model-value="getMarketHomeSelection(market)"
              :readonly="isPricesReadonly"
              :number-of-decimals="3"
              :is-error="isHomeSelectionError(market)"
              @update:modelValue="updateMarketHomeAwaySelection($event, market)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import {
  filter, includes, toLower, isNaN, find, cloneDeep,
} from 'lodash';
import TextInput from '@/components/common/TextInput';
import NumberInput from '@/components/common/NumberInput';
import Spinner from '@/components/common/Spinner';
import Toggle from '@/components/common/Toggle';
import Button from '@/components/common/Button';
import sportIds from '@/services/helpers/sports';
import Event from '@/services/helpers/event';
import EditMarketListModal from './EditMarketListModal';

const {
  BASKETBALL_ID,
  HOCKEY_ID,
} = sportIds;

export default {
  components: {
    TextInput,
    NumberInput,
    Spinner,
    Toggle,
    Button,
    EditMarketListModal,
  },
  props: {
    eventId: {
      type: String,
      default: '',
    },
    autoMode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const marketSearchValue = ref('');
    const markets = computed(() => store.getters.paramsManagerMarkets);
    const marketsFiltered = computed(() => filter(markets.value, (market) => includes(toLower(market.label), toLower(marketSearchValue.value))));
    const onMarketSearchValueChage = (val) => {
      marketSearchValue.value = val;
    };

    const eventData = computed(() => store.getters.playerSetupData || {});
    const isPricesReadonly = computed(() => !Event.isPrematch(eventData.value)
      || (eventData.value.sportId !== BASKETBALL_ID && eventData.value.sportId !== HOCKEY_ID) || props.autoMode);

    const getSelectionPrice = (price) => {
      let value = price.probability * 100;
      if (isNaN(+value)) return null;

      value = value % 1 !== 0
        ? parseFloat(value?.toFixed(1))
        : parseInt(value, 10);

      return value;
    };

    const getMainLine = (market) => {
      if (!market?.marketSummary?.isMainLine) return null;
      const mainLine = market?.marketSummary?.mainLine;

      return mainLine;
    };

    const getMarketOverSelection = (market) => {
      const price = find(market.selections, { selectionType: { selectionCode: 'OVER' } })?.price;
      if (!price) return null;

      return getSelectionPrice(price);
    };

    const getMarketHomeSelection = (market) => {
      const price = find(market.selections, { selectionType: { selectionCode: 'HOME' } })?.price;
      if (!price) return null;

      return getSelectionPrice(price);
    };

    const showMarketOverSelection = (market) => getMarketOverSelection(market) || getMarketOverSelection(market) === 0;
    const showMarketHomeSelection = (market) => getMarketHomeSelection(market) || getMarketHomeSelection(market) === 0;
    const showMarketPrices = (market) => showMarketOverSelection(market) || getMainLine(market) || showMarketHomeSelection(market);

    const isOverSelectionError = (market) => {
      const overSelection = find(market.selections, { selectionType: { selectionCode: 'OVER' } });

      return overSelection?.isError;
    };

    const isHomeSelectionError = (market) => {
      const homeSelection = find(market.selections, { selectionType: { selectionCode: 'HOME' } });

      return homeSelection?.isError;
    };

    const updateMarket = (updatedMarket) => {
      store.dispatch('updateGameMarket', {
        updatedMarket,
      });
    };

    const updateMainLine = (newVal, market) => {
      const updatedMarket = cloneDeep(market);
      if (newVal) {
        updatedMarket.marketType.params.LINE = parseFloat(newVal);
        delete updatedMarket.isError;
        delete updatedMarket.isMainLineInvalid;
      } else {
        updatedMarket.isError = true;
        updatedMarket.isMainLineInvalid = true;
      }
      updateMarket(updatedMarket);
    };

    const updateMarketOverUnderSelection = (newVal, market) => {
      const updatedMarket = cloneDeep(market);
      const overSelection = find(updatedMarket.selections, { selectionType: { selectionCode: 'OVER' } });
      const underSelection = find(updatedMarket.selections, { selectionType: { selectionCode: 'UNDER' } });
      if (!overSelection || !underSelection) return;
      const newValFormatted = parseFloat(newVal) / 100;
      if (newVal) {
        overSelection.price.probability = newValFormatted;
        underSelection.price.probability = 1 - newValFormatted;
        delete updateMarket.isError;
        delete overSelection.isError;
      } else {
        updatedMarket.isError = true;
        overSelection.isError = true;
      }
      updateMarket(updatedMarket);
    };

    const updateMarketHomeAwaySelection = (newVal, market) => {
      const updatedMarket = cloneDeep(market);
      const homeSelection = find(updatedMarket.selections, { selectionType: { selectionCode: 'HOME' } });
      const awaySelection = find(updatedMarket.selections, { selectionType: { selectionCode: 'AWAY' } });
      if (!homeSelection || !awaySelection) return;
      if (newVal) {
        const newValFormatted = parseFloat(newVal) / 100;
        homeSelection.price.probability = newValFormatted;
        awaySelection.price.probability = 1 - newValFormatted;
        delete updateMarket.isError;
        delete homeSelection.isError;
      } else {
        updatedMarket.isError = true;
        homeSelection.isError = true;
      }
      updateMarket(updatedMarket);
    };

    const isCustomizingMarketTemplatesVisible = computed(() => route.name === 'params-setup');
    const isCustomizingMarketTemplatesSupported = computed(() => !!store.getters.gameParamsSelectedMarkets);
    const isProcessingCustomizedMarketTemplates = computed(() => store.getters.gameParamsSelectedMarketsProcessing);
    const isUsingDefaultMarketTemplates = computed(() => store.getters.gameParamsSelectedMarkets?.fullTemplate);
    const customMarketTemplateIds = computed(() => store.getters.gameParamsSelectedMarkets?.marketTemplateIds);

    const isMarketListOpen = ref(false);
    const openMarketList = () => {
      isMarketListOpen.value = true;
    };
    const closeMarketList = () => {
      isMarketListOpen.value = false;
    };
    const setUsingDefaultMarketTemplates = (newIsUsingDefaultMarketTemplates) => {
      if (isProcessingCustomizedMarketTemplates.value || !isCustomizingMarketTemplatesSupported.value) return;
      store.dispatch('setGameParamsSelectedMarket', {
        marketTemplateIds: customMarketTemplateIds.value,
        fullTemplate: newIsUsingDefaultMarketTemplates,
      });
    };
    const setCustomMarketTemplateIds = async (newCustomMarketTemplateIds) => {
      if (isProcessingCustomizedMarketTemplates.value || !isCustomizingMarketTemplatesSupported.value) return;
      await store.dispatch('setGameParamsSelectedMarket', {
        fullTemplate: isUsingDefaultMarketTemplates.value,
        marketTemplateIds: newCustomMarketTemplateIds,
      });
      closeMarketList();
    };

    return {
      eventData,
      marketsFiltered,
      marketSearchValue,
      onMarketSearchValueChage,
      getMainLine,
      showMarketOverSelection,
      getMarketOverSelection,
      showMarketHomeSelection,
      getMarketHomeSelection,
      showMarketPrices,
      isPricesReadonly,
      updateMainLine,
      updateMarketOverUnderSelection,
      isOverSelectionError,
      updateMarketHomeAwaySelection,
      isHomeSelectionError,
      isCustomizingMarketTemplatesVisible,
      isCustomizingMarketTemplatesSupported,
      isProcessingCustomizedMarketTemplates,
      isUsingDefaultMarketTemplates,
      customMarketTemplateIds,
      isMarketListOpen,
      openMarketList,
      closeMarketList,
      setUsingDefaultMarketTemplates,
      setCustomMarketTemplateIds,
    };
  },
};
</script>

<style lang="scss">
  .params-setup-markets-root {
    .markets-header {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
      }
    }
    .markets-data {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      padding: 0 12px;
      .market-item {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex-wrap: wrap;
        gap: 6px;
        border-radius: 4px;
        padding: 4px;
        flex: 0 0 auto;

        .market-info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 13px;
        }

        .market-prices {
          width: 100%;
          display: flex;
          align-items: center;
        }

        .market-price {
          .number-input {
            input {
              width: 48px;
              height: 33px;
              padding: 0 4px;
              text-align: center;
              font-size: 14px;
              border-radius: 0;
              border-right-width: 0;
              position: relative;
              z-index: 1;

              &:focus {
                z-index: 2;
              }
            }
          }
          &:first-child {
            .number-input input {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }
          }
          &:last-child {
            .number-input input {
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              border-right-width: 1px;
            }
          }
        }
      }
    }

    .market-header-inputs {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .market-template-control {
      display: flex;
      align-items: center;
      gap: 16px;
      position: relative;

      &--is-processing {
        cursor: not-allowed;
      }

      &__toggle {
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
      }
    }
  }
</style>
