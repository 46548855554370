<template>
  <div class="af-player-params-by-team-root">
    <ParamsTabs
      class="players-type-tabs"
      :tabs="playersTypeTabs"
      :active-tab="activeTab"
      @onTabChange="onTabChange"
    />
    <div
      v-if="activeTabTeamData.length"
      class="af-player-params-by-team-list-root"
    >
      <AmericanFootballPlayerParamsSingle
        v-for="player in activeTabTeamData"
        :key="player.playerId"
        :player-data="player"
        :active-tab="activeTab"
        :team-label="teamLabel"
        :current-catcher-index="currentCatcherIndex"
        :is-calculated-mode="isCalculatedMode"
        @onParamUpdate="updatePlayerParam"
      />
    </div>
    <div
      v-else
      class="af-player-params-by-team-list-root-no-data"
    >
      There is no {{ toLower(activeTab.label) }} data.
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import {
  filter, cloneDeep, find, set, toLower, findIndex,
  orderBy,
} from 'lodash';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import ParamsTabs from '@/components/player-setup/ParamsTabs';
import AmericanFootballPlayerParamsSingle from './AmericanFootballPlayerParamsSingle';
import { updateTeamPlayersPositionValue } from '@/components/player-setup/params-helper';

export default {
  emits: ['onPlayersTypeTabChange'],
  props: {
    teamData: {
      type: Object,
      default: () => ({}),
    },
    teamDataFiltered: {
      type: Object,
      default: null,
    },
    teamLabel: {
      type: String,
      default: '',
    },
    currentCatcherIndexProp: {
      type: Number,
      default: null,
    },
    isCalculatedMode: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ParamsTabs,
    AmericanFootballPlayerParamsSingle,
  },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const playersTypeTabs = computed(() => {
      const tabs = [
        {
          key: 'quarterbacks',
          label: 'Quarterbacks',
        },
        {
          key: 'non-quarterbacks',
          label: 'Non-Quarterbacks',
        },
        {
          key: 'kickers',
          label: 'Kickers',
        },
      ];
      return tabs;
    });
    const activeTab = ref(playersTypeTabs.value[0]);
    const activeTabTeamData = computed(() => {
      let data;
      if (activeTab.value.key === 'quarterbacks') {
        data = filter(props.teamDataFiltered || props.teamData, { isQuarterback: true });
      } else if (activeTab.value.key === 'non-quarterbacks') {
        data = filter(props.teamDataFiltered || props.teamData, { isOffensiveNonQB: true });
      } else if (activeTab.value.key === 'kickers') {
        data = filter(props.teamDataFiltered || props.teamData, { isKicker: true });
      }
      return orderBy(data, 'playerPositionIndex');
    });

    const onTabChange = (tab) => {
      activeTab.value = tab;
      emit('onPlayersTypeTabChange', tab.key);
    };
    const playersData = computed(() => {
      if (route.name === 'trading-ui') {
        return store.getters.tradingPlayerProps;
      }
      if (route.name === 'trading-ui-multiview') {
        return store.getters.multiviewPlayerParams;
      }
      return store.getters.playerSetupTableData;
    });
    const currentCatcherIndex = computed(() => props.currentCatcherIndexProp || find(playersData.value[props.teamLabel], ({ offensiveNonQBParams }) => offensiveNonQBParams?.isCatcher)?.playerIndex);
    const updatePlayerParam = ({
      playerId, paramKey, value, updateKey, quarterbackParamsUpdated,
    }) => {
      const playersDataUpdated = cloneDeep(playersData.value);
      let playerDataByTeamUpdated = cloneDeep(props.teamData);
      const updatedRow = cloneDeep(find(playerDataByTeamUpdated, { playerId }));
      const updatedPlayerIndex = findIndex(playerDataByTeamUpdated, (player) => player.playerId === updatedRow.playerId);

      if (paramKey === 'playerPositionIndex') {
        playerDataByTeamUpdated = updateTeamPlayersPositionValue(playerDataByTeamUpdated, playerId, value, playerDataByTeamUpdated[updatedPlayerIndex]?.playerPositionIndex);
      }
      if (updateKey) {
        if (quarterbackParamsUpdated) {
          const updatedQuarterbackParams = cloneDeep(updatedRow.quarterbackParams);
          set(updatedQuarterbackParams, updateKey, value);
          set(updatedRow, 'quarterbackParams', updatedQuarterbackParams);
        } else {
          const updatedOffensiveNonQBParams = cloneDeep(updatedRow.offensiveNonQBParams);
          set(updatedOffensiveNonQBParams, updateKey, value);
          set(updatedRow, 'nonQuarterbackParams', updatedOffensiveNonQBParams);
        }
      } else {
        set(updatedRow, paramKey, value);
      }

      playerDataByTeamUpdated[updatedPlayerIndex] = updatedRow;
      if (route.name === 'trading-ui') {
        playersDataUpdated[props.teamLabel].playerProps = playerDataByTeamUpdated;
        store.dispatch('updateTradingPlayerProps', playersDataUpdated);
        return;
      } if (route.name === 'trading-ui-multiview') {
        playersDataUpdated[props.teamLabel].playerProps = playerDataByTeamUpdated;
        store.dispatch('updateMultiviewPlayerProps', playersDataUpdated);
        return;
      }
      playersDataUpdated[props.teamLabel] = playerDataByTeamUpdated;
      store.dispatch('setPlayerSetupTableData', playersDataUpdated);
    };

    return {
      playersTypeTabs,
      activeTab,
      activeTabTeamData,
      onTabChange,
      updatePlayerParam,
      toLower,
      currentCatcherIndex,
    };
  },
};
</script>

<style lang="scss">
.af-player-params-by-team-root {
  height: 100%;
  .players-type-tabs {
    padding-left: 0;
    padding-right: 0;
  }

  .af-player-params-by-team-list-root {
    height: calc(100% - 64px);
    overflow-y: auto;
  }
  .af-player-params-by-team-list-root-no-data {
    height: calc(100% - 64px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
  }

  .modal {
    .modal__header {
      padding: 16px 16px 0 16px;
      .modal__title {
        padding: 0;
      }
    }
    .modal__content {
      padding: 16px;
    }
  }
}
</style>
