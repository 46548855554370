<template>
  <div class="params-tabs-root">
    <div
      v-for="tab in tabs"
      :key="tab.key"
      class="params-tabs-item"
      :class="{ 'active': tab.key === activeTab.key }"
      @click="onTabChange(tab)"
    >
      {{ tab.label }}
    </div>
    <div class="params-tab-root-info">
      <div
        v-if="playerSetupUnpublishedChanges && mainHeaderTab"
        class="params-tab-root-info-message"
      >
        <Tooltip
          text="Please re-fetch projections, check prices and republish them if they are fine."
          right
        >
          <Icon name="alert-cycle" />
        </Tooltip>
        <span>Please re-fetch projections, check prices and republish them if they are fine.</span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';

export default {
  components: {
    Icon,
    Tooltip,
  },
  emits: ['onTabChange'],
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    activeTab: {
      type: Object,
      default: () => {},
    },
    mainHeaderTab: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(_, { emit }) {
    const store = useStore();
    const onTabChange = (tab) => emit('onTabChange', tab);
    const playerSetupUnpublishedChanges = computed(() => store.getters.playerSetupUnpublishedChanges);
    return {
      onTabChange,
      playerSetupUnpublishedChanges,
    };
  },
};
</script>

<style lang="scss">
  .params-tabs-root {
    display: flex;
    height: 48px;
    padding: 24px 16px 0 16px;
    font-size: 14px;
    line-height: 16px;
    border-bottom: 2px solid $gray400;
    position: relative;

    .params-tabs-item {
      padding: 0 8px 8px 8px;
      margin-bottom: -2px;
      cursor: pointer;

      &.active {
        border-bottom: 2px solid $brandPrimary500;
      }
    }

    .params-tab-root-info {
      display: flex;
      align-items: center;
      position: absolute;
      height: 100%;
      right: 16px;
      top: 0;

      .params-tab-root-info-message {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 4px;
        border-radius: 4px;
        background-color: rgba(232, 239, 255, 1);
        color: #0E3999;
        font-size: 14px;
        line-height: 16px;

        .tooltip-element {
          width: 16px;
          height: 16px;
          .icon {
            svg path {
              fill: $white;
              stroke: $white;
              &:first-child {
                fill: #165fff;
                stroke: #165fff;
              }
            }
          }
        }
      }
    }
  }
</style>
