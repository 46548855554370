<template>
  <div class="game-param-toggle-input">
    <div class="game-param-single-header">
      <div class="short-name">
        {{ param.shortName }}
      </div>
      <Tooltip
        v-if="param.longName"
        :text="param.longName"
        right
      >
        <Icon
          class="game-param-single-name-icon"
          name="info"
        />
      </Tooltip>
    </div>
    <div class="toggle-container">
      <Toggle
        :model-value="param.value"
        :disabled="param.disabled || disabled"
        @update:model-value="value => { onParamChange(value, param) }"
      />
    </div>
  </div>
</template>

<script>
import Toggle from '@/components/common/Toggle';
import Tooltip from '@/components/common/Tooltip';
import Icon from '@/components/common/Icon';

export default {
  emits: ['paramChange'],
  props: {
    param: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Toggle,
    Tooltip,
    Icon,
  },
  setup(_, { emit }) {
    const onParamChange = (value, param) => {
      emit('paramChange', value, param);
    };
    return {
      onParamChange,
    };
  },
};
</script>

<style lang="scss">
.game-param-toggle-input {
  width: 60px;
  .game-param-single-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .short-name {
      display: inline-block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $gray800;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 12px;
      margin-bottom: 2px;
      font-weight: 600;
    }
    .game-param-single-name-icon {
      cursor: pointer;
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
  .toggle-container {
    height: 32px;
    display: flex;
    align-items: center;
  }
}
</style>
